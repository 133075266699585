// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-book-trailers-js": () => import("./../../../src/pages/book-trailers.js" /* webpackChunkName: "component---src-pages-book-trailers-js" */),
  "component---src-pages-compliance-policy-js": () => import("./../../../src/pages/compliance-policy.js" /* webpackChunkName: "component---src-pages-compliance-policy-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marketing-services-js": () => import("./../../../src/pages/marketing-services.js" /* webpackChunkName: "component---src-pages-marketing-services-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-publishing-services-js": () => import("./../../../src/pages/publishing-services.js" /* webpackChunkName: "component---src-pages-publishing-services-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-submission-js": () => import("./../../../src/pages/submission.js" /* webpackChunkName: "component---src-pages-submission-js" */),
  "component---src-pages-terms-cons-js": () => import("./../../../src/pages/terms-cons.js" /* webpackChunkName: "component---src-pages-terms-cons-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-vlogs-js": () => import("./../../../src/pages/vlogs.js" /* webpackChunkName: "component---src-pages-vlogs-js" */)
}

