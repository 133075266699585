const Theme = {
  fonts: {
    main: "Rubik, sans-serif",
    secondary: "Roboto, sans-serif",
    tertiary: "Sansita One"
  },
  colors: {
    main1: "hsl(217, 50%, 48%)",
    main2: "hsl(217, 78%, 65%)",
    main3: "	hsl(209, 78%, 53%)",
    accent1: "hsl(191, 100%, 50%)",
    accent2: "hsl(176, 100%, 50%)",
    accent3: "#FAA500", // yellow
    // accent3: "#9691FF",
    accent4: "#4B4BFF",
    accent5: "#e85d49", // red
    accent6: "#6dc0e3", // sky blue
    accent7: "#1c587d", // dark blue
    accent8: "#9691FF",
    accentSkyBlue: "#2A8AE5",
    dark1: "hsl(227, 2%, 12%)",
    dark2: "hsl(227, 2%, 45%)",
    dark3: "hsl(227, 2%, 64%)",
    dark4: "#333",
    light1: "hsl(0, 0%, 50%)",
    light2: "hsl(0, 0%, 80%)",
    light3: "#fff",
  },
  breakpoints: {
    mobile: "only screen and (max-width: 50rem)",
    tablet: "only screen and (max-width: 65rem)",
  },
  spacings: {
    spacings: {
      xxSmall: ".25rem",
      xSmall: ".5rem",
      small: "1rem",
      medium: "2rem",
      large: "3rem",
      xLarge: "4rem",
      xxLarge: "6rem",
    },
  },
  animations: {
    button: "box-shadow 0.3s ease",
    link: "color 0.2s ease",
  },
  shadows: {
    shadow1: "0px 5px 20px rgba(30,30,31,0.05)",
  },
}

export default Theme
