import React, { useState, useEffect , useRef } from "react"
import { IoIosArrowUp } from "@react-icons/all-files/io/IoIosArrowUp"
import { RiMailSendLine } from "@react-icons/all-files/ri/RiMailSendLine"
import styled from "styled-components"
import { Link } from "gatsby"

const ScrollToTop = ({ showBelow }) => {
  const [show, setShow] = useState(showBelow ? false : true)

  const handleScroll = () => {
    if (window.pageYOffset > showBelow) {
      if (!show) setShow(true)
    } else {
      if (show) setShow(false)
    }
  }

  useEffect(() => {
    if (showBelow) {
      window.addEventListener("scroll", handleScroll)
      return () => window.removeEventListener(`scroll`, handleScroll)
    }
  })

  const handleClick = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` })
  }

  return (
    <Wrapper>
      {show && (
        <Container onClick={handleClick}>
          <span>Scroll to top</span>
          <IconScroll />
        </Container>
      )}
      {show && (
        <Link to="/contact">
          <ContainerSubmission>
            <span>Send as an email</span>
            <IconSubmission />
          </ContainerSubmission>
        </Link>
      )}
    </Wrapper>
  )
}

export default ScrollToTop
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column-reverse;
  position: fixed;
  bottom: 10vh;
  gap: 1rem;
  z-index: 5;
  right: 6%;
`

const Container = styled.div`
  ${'' /* position: fixed; */}
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background: ${props => props.theme.colors.main1};
  bottom: 4vh;
  right: 6%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: ${props => props.theme.colors.main2};
    transform: translateY(-10px);
  }

  span {
    visibility: hidden;
    width: 120px;
    font-size: clamp(0.7rem, 5vw, 0.9rem);
    background-color: #dcdcdc;
    color: ${props => props.theme.colors.main1};
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: fixed;
    bottom: 2.5vh;
    right: 110%;
    z-index: 10;

    :after {
      content: " ";
      position: absolute;
      top: 50%;
      left: 100%; /* To the right of the tooltip */
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent transparent #dcdcdc;
    }
  }

  &:hover span {
    visibility: visible;
  }

  @media screen and (max-width: 768px) {
    height: 30px;
    width: 30px;
  }
`

const IconScroll = styled(IoIosArrowUp)`
  font-size: 2rem;
  color: ${props => props.theme.colors.light3};

  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
`

const ContainerSubmission = styled.div`
  ${'' /* position: fixed; */}
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background: ${props => props.theme.colors.main1};
  bottom: 16vh;
  right: 6%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  @media screen and (max-width: 768px) {
    bottom: 16vh;
    height: 30px;
    width: 30px;
  }

  &:hover {
    background: ${props => props.theme.colors.main2};
    transform: translateY(-10px);
  }

  span {
    visibility: hidden;
    width: 120px;
    font-size: clamp(0.7rem, 5vw, 0.9rem);
    background-color: #dcdcdc;
    color: ${props => props.theme.colors.main1};
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: fixed;
    bottom: 2.5vh;
    right: 110%;
    z-index: 10;

    :after {
      content: " ";
      position: absolute;
      top: 50%;
      left: 100%; /* To the right of the tooltip */
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent transparent #dcdcdc;
    }
  }

  &:hover span {
    visibility: visible;
  }
`

const IconSubmission = styled(RiMailSendLine)`
  font-size: 2rem;
  color: ${props => props.theme.colors.light3};

  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
`
